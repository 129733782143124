import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroAntdModule } from '@/app/ng-antd.module';
import { IconsProviderModule } from '@/app/icons-provider.module';
import { LoginComponent } from './login/login.component';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { CustomErrorHandler } from '@/class/global-error-handler.class';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { config, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';

registerLocaleData(en);

// function appInitializer(http: HttpClient) {
//   return () => {
//     const config: any = http.get('/config.json').pipe(
//       tap((config: any) => {
//         localStorage.setItem('BASEURL', config.apiBaseUrl);
//       })
//     );
//     return firstValueFrom(config);
//   };
// }
@NgModule({
  declarations: [AppComponent, LoginComponent, ChangePasswordComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    IconsProviderModule,
  ],
  providers: [
    {
      // provide: LocationStrategy, useClass: HashLocationStrategy
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [HttpClient],
    // },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
