<div class="h-screen md:flex">
  <div
    class="relative overflow-hidden md:flex w-1/2 i justify-around items-center hidden"
    style="background-color: white"
  >
    <img
      src="https://admin.tendertruck.in/assets/images/logo.png"
      class="logo w-80 select-none"
      alt="logo"
      style="position: absolute; top: 1rem; left: 1rem; width: 10rem"
    />

    <div>
      <img src="./assets/logo/loginTheme.png" class="logo w-80 select-none" alt="logo" style="width: 85%" />
      <!-- <a
        target="_blank"
        href=""
        class="w-28 bg-white text-tender-truck mt-4 py-2 flex justify-center font-bold mb-2 select-none"
      >
        Read More
      </a> -->
    </div>
  </div>
  <div class="flex md:w-1/2 justify-center py-10 items-center" style="background: rgb(245, 245, 245)">
    <form nz-form [formGroup]="validateForm" (ngSubmit)="login()">
      <h1 class="text-gray-800 font-bold text-2xl mb-1 select-none">Hello Again!</h1>
      <p class="text-sm font-normal text-gray-600 mb-7 select-none">Welcome Back</p>
      <nz-form-item class="mb-2">
        <nz-form-control nzErrorTip="Please input a valid email!">
          <div class="flex items-center border-2 py-3 px-3 mb-2" style="border-radius: 10px; background: white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none select-none"
              formControlName="email"
              type="text"
              placeholder="Email Address"
            />
          </div>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Password is required">
          <div class="flex items-center border-2 py-2 px-3 mb-2" style="border-radius: 10px; background: white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none select-none"
              [type]="showPassword ? 'text' : 'password'"
              formControlName="password"
              placeholder="Password"
            />
            @if (showPassword) {
            <span
              nz-icon
              tabindex="0"
              nzType="eye-invisible"
              class="text-gray-400 text-lg"
              nzTheme="fill"
              (click)="showPassword = false"
            ></span>
            } @else {
            <span
              nz-icon
              nzType="eye"
              tabindex="0"
              class="text-gray-400 text-lg"
              nzTheme="fill"
              (click)="showPassword = true"
            ></span>
            }
          </div>
        </nz-form-control>
      </nz-form-item>
      <button
        type="submit"
        class="block w-full bg-indigo-600 mt-4 py-2 text-white font-semibold mb-2 select-none"
        style="background: #fbec12; color: black; border-radius: 6px"
      >
        Login
      </button>
    </form>
  </div>
</div>
