<div class="min-h-screen flex items-center justify-center">
  <div class="max-w-md w-full p-6 bg-white rounded-lg shadow-lg">
    <div class="flex justify-center mb-8">
      <img src="https://admin.tendertruck.in/assets/images/logo.png" alt="Logo" class="w-30 h-20" />
    </div>
    @if(!passwordSubmitted){
    <h1 class="text-2xl font-semibold text-center text-gray-500 mt-8 mb-2">Change Password</h1>
    <div class="text-center">
      <p class="text-sm mb-6">
        <a href="mailto:manager@domain.tld" class="text-cyan-600">{{ email }}</a>
      </p>
    </div>
    <form class="bg-white" nz-form [formGroup]="validateForm" (ngSubmit)="changePassword()">
      <nz-form-item class="mb-2">
        <nz-form-control nzErrorTip="New Password is required">
          <div class="flex items-center border-2 py-2 px-3 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none select-none w-full"
              [type]="showNewPassword ? 'text' : 'password'"
              formControlName="newPassword"
              placeholder="New Password"
            />
            @if (showNewPassword) {
            <span
              nz-icon
              tabindex="0"
              nzType="eye-invisible"
              class="text-gray-400 text-lg"
              nzTheme="fill"
              (click)="showNewPassword = false"
            ></span>
            } @else {
            <span
              nz-icon
              nzType="eye"
              tabindex="0"
              class="text-gray-400 text-lg"
              nzTheme="fill"
              (click)="showNewPassword = true"
            ></span>
            }
          </div>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzErrorTip]="errorMessageForConfirmPassword()">
          <div class="flex items-center border-2 py-2 px-3 mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd"
              />
            </svg>
            <input
              class="pl-2 outline-none border-none select-none w-full"
              [type]="showConfirmPassword ? 'text' : 'password'"
              formControlName="confirmPassword"
              placeholder="Confirm Password"
            />
            @if (showConfirmPassword) {
            <span
              nz-icon
              tabindex="0"
              nzType="eye-invisible"
              class="text-gray-400 text-lg"
              nzTheme="fill"
              (click)="showConfirmPassword = false"
            ></span>
            } @else {
            <span
              nz-icon
              nzType="eye"
              tabindex="0"
              class="text-gray-400 text-lg"
              nzTheme="fill"
              (click)="showConfirmPassword = true"
            ></span>
            }
          </div>
        </nz-form-control>
      </nz-form-item>
      <button
        type="submit"
        [disabled]="!this.validateForm.valid"
        class="block w-full bg-indigo-600 mt-4 py-2 text-white font-semibold mb-2 select-none"
        [ngClass]="{
          'bg-indigo-300': !this.validateForm.valid
        }"
      >
        Submit
      </button>
    </form>
    }@else { @if(passwordSubmitted === 'success'){
    <nz-result nzStatus="success" nzTitle="Password changed successfully!">
      <div nz-result-extra>
        <button nz-button nzType="primary" (click)="goToLogin()">Go to Login</button>
      </div>
    </nz-result>
    } @else {
    <nz-result nzStatus="error" [nzTitle]="message">
      <div nz-result-extra>
        <button nz-button nzType="primary" nz-danger (click)="goToLogin()">Go to Login</button>
      </div>
    </nz-result>
    }}
  </div>
</div>
