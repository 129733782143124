import { GlobalUnsubscribe } from '@/class/global-unsubscribe.class';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, takeUntil, throwError } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css',
})
export class LoginComponent extends GlobalUnsubscribe {
  router = inject(Router);

  fb = inject(NonNullableFormBuilder);

  showPassword = false;

  validateForm: FormGroup<{
    email: FormControl<string>;
    password: FormControl<string>;
  }> = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required]],
  });

  async login(): Promise<void> {
    if (this.validateForm.valid) {
      this.control
        .login(this.validateForm.value)
        .pipe(
          catchError((err) => {
            return throwError(() => {
              return err;
            });
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe((res) => {
          if (res.code) {
            localStorage.setItem('tender-truck-token', res.result.token);
            this.router.navigateByUrl('/auth/home');
            // this.control.profile();

            this.control.openNotification('Login Successfully');
            this.validateForm.reset({
              email: '',
              password: '',
            });
          } else {
            this.control.openNotification(res.message, 'error');
          }
        });
    } else {
      Object.values(this.validateForm.controls).forEach((control) => {
        if (control.invalid) {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }
}
